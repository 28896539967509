import React, { Component } from "react"

// Libs
import anime from "animejs/lib/anime.es.js"

// Styles
import Styles from "./ChoicesScale.module.styl"

class ChoicesScale extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.canvas = React.createRef()
        this.canvasScale = React.createRef()
        this.indicators = []
    }

    state = {
        choiceSelected: -1,
    }

    componentDidMount() {}

    onMouseMove(e, index) {
        const currentIndicator = this.indicators[index].current

        const rect = e.target.getBoundingClientRect(),
            x = (e.clientX - rect.left) * 0.6,
            y = (e.clientY - rect.top) * 0.6

        anime.set(currentIndicator, {
            left: `${x}px`,
            top: `${y}px`,
        })

        this.props.canvasScale.current.onMouseOver()
    }

    onMouseOut(index) {
        const currentIndicator = this.indicators[index].current

        anime({
            targets: currentIndicator,
            left: "50%",
            top: "50%",
            duration: 300,
        })

        this.props.canvasScale.current.onMouseOut()
    }

    onClick(e, index) {
        e.preventDefault()

        this.setState(
            {
                choiceSelected: index,
            },
            this.props.onClickBtn(
                index,
                this.props.choices[index].conditionalTarget
            )
        )
    }

    renderButtons(choices) {
        this.indicators = []

        const Buttons = choices.map((choice, index) => {
            const indicatorRef = React.createRef()
            this.indicators.push(indicatorRef)

            return (
                <div
                    className={`${Styles.ChoicesScale__choices__item} ${
                        this.state.choiceSelected === index
                            ? Styles.ChoicesScale__choices__item__active
                            : ""
                    }`}
                    key={`scale-button-${index}`}
                >
                    <span
                        className={`teasing-1 ${Styles.ChoicesScale__choices__item__top}`}
                    >
                        {choice.title}
                    </span>
                    <button
                        className={Styles.ChoicesScale__choices__item__bottom}
                        onMouseEnter={(e) => {
                            e.persist()
                            this.onMouseMove(e, index)
                        }}
                        onMouseMove={(e) => {
                            e.persist()
                            this.onMouseMove(e, index)
                        }}
                        onMouseOut={() => {
                            this.onMouseOut(index)
                        }}
                        onClick={(e) => {
                            e.persist()
                            this.onClick(e, index)
                        }}
                    >
                        <span
                            className={
                                Styles.ChoicesScale__choices__item__indicator
                            }
                            ref={indicatorRef}
                        ></span>
                    </button>
                </div>
            )
        })

        return Buttons
    }

    render() {
        const Buttons = this.renderButtons(this.props.choices)

        return (
            <div className={Styles.ChoicesScale}>
                <div className={Styles.ChoicesScale__inner}>
                    <div className={Styles.ChoicesScale__choices}>
                        {Buttons}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChoicesScale
